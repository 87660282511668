<template>
  <div class="Site PageBox">
    <van-nav-bar
      fixed
      :border="false"
      :title="$t('hall.default[0]')" @click-right="onClickRight"
    ><van-icon name="./skin/task01.png" slot="right" size="22"/>
    </van-nav-bar>
    <div class="info flex_ac">
      <img class="head" :src="`./static/head/${UserInfo.header}`" />
      <div>
        <div class="userName">{{ UserInfo.username.substring(0,15) }}</div>
        <div class="flex_ac">
          <div class="vip">VIP{{ UserInfo.vip_level }}</div>
          <div class="xx"> {{ $t("wallet.default[13]") }}</div>
        </div>
      </div>
        <!-- {{ $t("user.default[2]") }}:{{ UserInfo.idcode }} -->
    </div>

    <div class="ScrollBox">
    <div class="tool">
        <table style="text-align: left;width: 95%;line-height: 25px">
          <tr style="color:#000;font-size:16px"><td> {{ $t("hall.default[1]") }}</td></tr>
          <tr style="font-size:24px;font-weight:600;color:#000"><td> {{ parseFloat(UserInfo.balance).toFixed(2)}}</td></tr>
          <tr style="color:#888"><td> USDT</td></tr>
          <tr><td> <span style="text-decoration:underline;">{{ $t("hall.default[8]") }}: {{ UserInfo.remains_numbers }} USDT </span></td></tr>
        </table>

      <div class="btn_sss flex_sb">
        <div class="btn_sss1" @click="gotoBuy">{{$t('hall.default[2]')}}</div>
        <div class="btn_sss2" @click="$router.push('sell')">{{$t('hall.default[3]')}}</div>
      </div>
    </div>

    <van-swipe :autoplay="2000" :vertical="isVertical" v-if="robotList.length>0"
               indicator-color="#888"
               class="hallswipe">
      <van-swipe-item v-for="(item, index) in robotList" :key="index">
        <img class="robotheader" :src="`./static/head/${item.header}`" />
        <div style="margin-top: -28px;margin-left: 40px">
          {{item.username}}
          {{$t('common5[0]')}} {{item.amount}}USDT {{item.time}} {{item.timedesc}} {{$t('common5[2]')}}
        </div>
      </van-swipe-item>
    </van-swipe>
    <div class="title01">
      <van-grid :border="false" :column-num="2" icon-size="40" gutter="5">
        <van-grid-item>
          <div :class="active==0?'btn_active':'btn0'" @click="changeTab(0)">
            <span >{{ $t("buy3[3]") }}</span></div>
        </van-grid-item>
        <van-grid-item>
          <div :class="active==1?'btn_active':'btn0'" @click="changeTab(1)">
            <span >{{ $t("buy3[4]") }}</span></div>
        </van-grid-item>
      </van-grid>
    </div>
      <div class="ScrollBox">
          <van-list
                  v-model="isLoad"
                  :finished="isFinished"
                  :finished-text="listData[active].length ? $t('vanPull[0]') : $t('vanPull[1]')"
                  @load="onLoad"
                  :class="{ Empty: !listData[active].length }"
          >
            <div v-for="item in listData[active]" class="records">
              <div class="sellitem">
                  <div class="text_name"> {{item.username.slice(0, 4) + '******' + item.username.slice(10)}}</div>
                  <div class="text_name1"> <span> {{ $t("hall.list[6]") }}</span> {{parseFloat(item.min_number).toFixed(2)}}-{{parseFloat(item.max_number).toFixed(2)}} USDT</div>
                  <div class="text_name1"> <span> {{ $t("hall.list[0]") }}</span> {{parseFloat(item.total_number).toFixed(2)}} USDT</div>
                  <div class="text_name1"> <span> {{ $t("hall.list[2]") }}</span> {{parseFloat(item.remains_number).toFixed(2)}} USDT</div>
                <br>
                  <div class="text_name1"> <span> {{ $t("hall.list[1]") }}</span> {{parseFloat(item.price).toFixed(2)}}</div>


                  <div style="text-align: right;padding-bottom:10px">  <van-button size="normal" color="#59D4E4"  style="width: 80px;height: 28px;border-radius:14px"
                                                              @click="gotoTask(2,item)">
                    <span style="font-size: 14px" >{{ $t("hall.default[10]") }}</span></van-button></div>
              </div>
            </div>
          </van-list>
        <div style="height: 80px;"/>
      </div>
    </div>
    <Footer />
    <van-dialog v-model="show_buy"  :title="$t('hall.list[7]')" @confirm="addNewOrder"
                :show-confirm-button="true"  :show-cancel-button="true" @cancel="cancelTask">
      <div style="width: 90%;margin-top: 10px;margin-bottom: 30px">
        <van-field
                v-model="postData.amount"
                :placeholder="$t('hall.list[4]')" style="margin-left: 20px"
        />
        <div style="margin-left: 30px;margin-top: 10px">{{$t('hall.list[8]')}}::{{parseFloat(postData.amount*currItem.price).toFixed(2)}} {{usdtinfo.sign}}</div>
      </div>
    </van-dialog>
    <van-dialog v-model="show_sell"  :title="$t('hall.list[3]')" @confirm="addNewOrder"
                :confirmButtonText="$t('common[2]')" :cancelButtonText="$t('common[3]')"
                :show-confirm-button="true"  :show-cancel-button="true" @cancel="cancelTask">
      <div style="width: 90%;margin-top: 10px;margin-bottom: 30px">
        <van-field
                v-model="postData.amount"
                :placeholder="$t('hall.list[4]')" style="margin-left: 20px"
        />
        <div style="margin-left: 30px;margin-top: 10px">{{$t('hall.list[5]')}}:{{parseFloat(postData.amount*currItem.price).toFixed(2)}} {{usdtinfo.sign}}</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: 'Help',
  components: {
  },
  props: [],
  data() {
    return {
      showCon: false,
      infoData: '',
      isVertical:true,
      show_sell:false,
      show_buy:false,
      postData:{task_id:0,amount:''},
      usdtamount:0,
      usdtinfo:null,
      currItem:{"price":0},
      robotList:[],
      selllist:[],
      listData: [[],[]],
      timer:null,
      timer2:null,
      isLoad: false,
      isFinished: false,
      isRefresh: false,
      pageNo: 0,
      hallInfo:{islock:1,shimingauth:1},
      active:0,
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    this.$Model.GetUserInfo();
    let info = this.UserInfo;
    // debugger
    // if (!info['shimingauth']||info['shimingauth']!=1){
    //   this.$router.push("/shimingauth");
    //   return;
    // }

    var that = this;

    that.findTradeStatus();
    this.timer = setInterval(() => {
      // console.log("get prices");
      that.findTradeStatus();
    }, 3000);

    that.findNoticeTasks()
    this.timer2 = setInterval(() => {
      // console.log("get prices");
      that.findNoticeTasks()
    }, 300000);

    this.usdtinfo = this.InitData.usdtinfo;

    // let param2 = {"task_type":2,status:1};
    // this.$Model.GetHallTaskList(param2,(data) => {
    //   if (data['code']==1){
    //     this.selllist = data['info']
    //   }
    // });
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {

  },
  beforeDestroy() {
    console.log("clear  hall timer");
    clearInterval(this.timer);
    clearInterval(this.timer2);
  },
  methods: {
    onLoad() {
      this.getListData("load");
    },
    changeTab(index){
      this.active = index;
      this.getListData("init");
    },
    onRefresh() {
      this.getListData("init");
    },
    findTradeStatus(){
      this.$Model.TradeStatus((data) => {
        console.log("findTradeStatus")
        if (data['code']==1){
          this.hallInfo = data['info'];
          let info = this.hallInfo;
          if (info['errauth']==1){
            this.$Dialog.Toast(this.$t('errauth[2]'));
            this.$router.push("/errauth");
          }else if (info['bindAccount']==1) {
            this.$Dialog.Toast(this.$t('common4[7]'));
            this.$router.push("/user/bindAccount");
          }else if (info['shimingauth']==1){
            this.$Dialog.Toast(this.$t('common4[8]'));
            this.$router.push("/shimingauth");
          }else if (info['islock']==2){
            this.$Dialog.Toast(this.$t('common5[5]'));
            this.$router.go(-1);
          }
        }
      });
    },
    findNoticeTasks(){
      console.log("fff findNoticeTasks")
      this.$Model.GetRobotTasks({},(data) => {
        if (data['code']==1){
          var list = data['info']
          list.forEach(item=>{
            var ttype = item['time_type'];
            var timedesc = this.$t('hall3['+ttype+']');
            item['timedesc'] = timedesc
          })
          this.robotList = list;
        }
      });
    },
    getListData(type) {
      this.isLoad = true;
      this.isRefresh = false;
      if (type == "load") {
        this.pageNo += 1;
      } else {
        this.pageNo = 1;
        this.isFinished = false;
      }

      // let param = {"task_type":1,status:1,page_no: this.pageNo,page_size:200};
      let param = {"ordertype":this.active,status:1,page_no: this.pageNo,page_size:200};
      this.$Model.GetHallTaskList(param,(data) => {
        if (data['code']==1){
          this.isLoad = false;
          data.info.forEach(item=>{
            var ctime = item.counttime;
            var countdesc = ''
            if (ctime==0){
              countdesc = this.$t('hall3[6]');
            }else{
              var countdesc = '';
              for (var i=0;i<ctime.length;i++){
                countdesc += ' '+ctime[i]+" "+this.$t('hall3['+i+']');
              }
            }
            item.countdesc = countdesc;
            var price_rate = (item.price-this.usdtinfo.price)/this.usdtinfo.price*100
            // price_rate = 13;
            price_rate = parseFloat(price_rate).toFixed(0);
            if (price_rate>0){
              price_rate = "+"+price_rate;
            }
            item.price_rate = price_rate
          });
          // this.selllist = data['info']
          if (type == "load") {
            if (this.pageNo == 1) {
              this.listData[this.active] = data.info;
            } else {
              this.listData[this.active] = this.listData[this.active].concat(data.info);
            }
          } else {
            this.listData[this.active] = data.info;
          }
          if (this.pageNo == data.data_total_page) {
            this.isFinished = true;
          } else {
            this.isFinished = false;
          }
        } else {
          this.listData[this.active] = [];
          this.isFinished = true;
        }
      });
    },
    onClickRight(){
      this.$router.push("/user/userTaskRecord");
    },
    gotoTask(index,item){
      if (this.hallInfo['islock']==2){
        this.$Dialog.Toast(this.$t('common5[5]'));
        return;
      }
      this.currItem = item;
      if (index==1){
        this.show_buy = true;
        this.show_sell = false;
      }else{
        this.show_sell = true;
        this.show_buy = false;
      }

    },
    cancelTask(){
      this.postData.amount = '';
    },
    addNewOrder() {
      let amount = parseFloat(this.postData['amount']);
      let item = this.currItem;
      let hallstatus = this.hallInfo;
      if (amount<=0){
        this.$toast(this.$t('hall.kbip[2]'));
        this.postData.amount = '';
        return;
      }else if (amount>item.remains_number){
        this.$toast(this.$t('hall.kbip[1]'));
        this.postData.amount = '';
        return;
      }else if (item.min_number>0&&(amount<item.min_number||amount>item.max_number)){
        this.postData.amount = '';
        this.$toast(this.$t('hall.kbip[2]'));
        return;
      }else if (amount>item.remains_number){

      }

      this.postData['task_type'] = 2;
      this.postData['task_id'] = item.id;
      this.postData['order_number'] = item['order_number'];
      this.$Model.SubmitTaskOrder(
              this.postData,
              (data) => {
                let msg = '';
                switch (data.code){
                  case 1:
                    msg = this.$t('common3[0]');
                    break;
                  case -1:
                    msg = this.$t('hall.kbip[2]');
                    break;
                  case -2:
                    msg = this.$t('hall[2]');
                    break;
                  case -3:
                    msg = this.$t('taskOrder2[1]');
                    break;
                  case -4:
                    msg = this.$t('common4[7]');
                    break
                  case -5:
                    msg = this.$t('common4[8]');
                    break
                  default:
                    msg = data.code_dec;
                }
                this.$Dialog.Toast(msg);
                if (data.code==1){
                  this.$router.push("/myTask");
                }
              }
      );
      this.postData.amount = '';
    console.log("xxxxxxxxx")
    },
    gotoBuy(){
      let usertype = this.UserInfo.user_type;
      if (usertype==0){
        this.$Dialog.Toast(this.$t("busAuth2[5]"));
        return;
      }else{
        this.$router.push("/buy");
      }
    }
  }
}
</script>
<style scoped lang="scss">
  .PageBox {
    background-image: url(~@/assets/img/new/hall_bg.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .van-nav-bar {
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-color: #0000;
  }
  .van-nav-bar::v-deep .van-nav-bar__title{
    color:#FFFFFF;
  }

  .robotheader{
    font-size: 19px;
    border-radius: 50%;
    background: pink;
    overflow: hidden;
    width: 32px;
    height: 32px;
  }

  .href {
    color: #AD890C;
  }

  .records {
    width: 92%;
    margin: auto;
    border-radius: 10px;
    background-color: #fff;
    margin-bottom: 10px;
  }
  .Content::v-deep img{
    max-width: 100%;
  }
  .van-cell__title{
    font-size: 14px;
    color: #000;
  }

  .ScrollBox{

  }

  .itemDesc{
    border:2px solid #5652FD;
    width: 36px;
    height: 36px;
    font-size: 12px;
    margin-left: 120px;
    text-align: center;
    background-color: #24303C;
    color: #fff;
    border-radius: 50%;
  }

  .PageBox ::v-deep  .van-grid-item__content {
    padding: 10px !important;
    display: flex;
    background-color:#0000;
  }

  .PageBox ::v-deep  .van-grid-item__text{
    color: #cccccc;
  }


  .title00 {
    background: #FDEAD9;
    width: 95%;
    color: #000;
    margin: auto;
    font-weight: bold;
    line-height: 40px;
    text-align: center
  }
  .MyHeader {
    font-size: 19px;
    border-radius: 50%;
    background: pink;
    overflow: hidden;
    width: 82px;
    height: 82px;
  }
  .MyHeader img {
    width: 100%;
  }

  .title01 {
    // background: #191C23;
    width: 95%;
    color: #000;
    margin: auto;
    font-weight: bold;
    line-height: 40px;
    text-align: center;
    margin-bottom: 10px;
    .btn_active{
      font-size: 16px;
      position: relative;
      &::after{
        position: absolute;
        content:'';
        width: 60px;
        left: calc(50% - 30px);
        bottom: 5px;
        height: 5px;
        border-radius: 2.5px;
        background: #3FC3D6;
      }
    }
    .btn0{
      font-size: 16px;
      color: #666;
    }
  }

  .sellitem {
    color: #333;
    text-align: left;
    font-size: 15px;
    padding: 0 10px;
    border-radius: 10px;
    .text_name{
      height: 36px;
      line-height: 36px;
      border-bottom: 1px solid #f1f1f1;
    }
    .text_name1{
      height: 26px;
      line-height: 26px;

        color: #000;
        font-weight: 600;
      span{
        font-weight: 400;
        width:100px;
        display: inline-block;
        color: #666;
      }
    }
  }

  .PageBox::v-deep .tool::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: -80%;
  }
  .tool {
    border-radius: 16px;
    background-color: #fff;
    color: #333;
    box-shadow: 0 1px 3px #dbdbdbdb;
    overflow: hidden;
    z-index: 99;
    margin: 5px 4%;
    padding: 15px 20px;
  }


  .ScrollBox::v-deep .van-cell{
    padding:20px 5px;
    border-bottom: 1px solid #D9D9D9;
  }
  .ScrollBox::v-deep .van-cell:last-child{
    border-bottom: 0px solid #D9D9D9;
  }
  .ScrollBox::v-deep .van-cell::after{
    display: none;
  }
  .ScrollBox::v-deep .van-icon-chat-o::before{
    content: '';
    border-radius: 50%;
    background: #191C23;
    width:8px;
    height: 8px;
  }
  .ScrollBox::v-deep .van-icon-chat-o{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ScrollBox::v-deep .van-cell__title{
    font-size: 16px;
    font-family: ui-serif;
  }
  .ScrollBox::v-deep h3{
    font-size: 1.05rem;
    font-family: emoji;
  }
  .van-cell::v-deep .van-icon{
    font-size: 18px;
    color:#333;
  }

  .hallswipe {
    width: 96%;
    margin-left: 6px;
    padding: 15px;
    height: 60px;
    z-index: 50;
    margin-top: 5px;
    margin-bottom: 5px;
    color:#333;
    background-color: #fff;
  }


  .ScrollBox ::v-deep  .van-tab :nth-of-type(1) div {
    background-color: #000;
    padding: 5px;
    border: 1px solid;
    border-radius: 20px;
    color: #fff;
  }

  .ScrollBox ::v-deep  .van-tab--active :nth-of-type(1) div {
    background-color: #F5C73A;
    padding: 5px;
    border-radius: 20px;
    color: #000;
  }
  .ScrollBox ::v-deep  .van-tab--active :nth-of-type(1) div div {
    background-color: #F5C73A;
    padding: 5px;
    border-radius: 20px;
    color: #000;
  }

  .PageBox::v-deep .van-popup__close-icon--top-right{
    color:#333;
    top:20px;
    right: 20px;
  }
  .PageBox::v-deep .Content{
    margin-top:40px;
    padding:0 20px;
  }
  .PageBox::v-deep .Content p{
    font-size: 13px;
    line-height: 1.7;
    font-family: 'vant-icon';
    font-weight: 400;
    color: #666666;
  }

.info{
  padding: 20px 30px 20px 30px;
  .head{
    height: 68px;
    width: 68px;
    border-radius: 34px;
    border: 2px solid #fff;
    margin-right: 10px;
  }
  .userName{
    font-size: 20px;
    color: #fff;
    margin-bottom: 6px;
  }
  .vip{
    background: url(~@/assets/img/new/vip.png) no-repeat;
    background-size: auto 100%;
    height: 20px;
    line-height: 20px;
    padding-left: 26px;
    color: #AB783F;
    font-size: 12px;
    font-weight: 600;
    width: 80px;
  }
  .xx{
    color: #f1f1f1;
  }
}

.btn_sss{
  margin-top: 10px;
  .btn_sss1{
    height: 48px;
    line-height: 48px;
    border-radius: 24px;
    width: calc(50% - 10px);
    background: #CAF3F7;
    border: 2px solid #8AE1ED;
    text-align: center;
    font-size: 16px;
    color: #7DDEEB;
    font-weight: 600;
  }
  .btn_sss2{
    height: 52px;
    line-height: 52px;
    border-radius: 26px;
    width: calc(50% - 10px);
    background: linear-gradient(45deg,#3FC3D6,#69E1F1);
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
  }
}
</style>
